
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { themeName } from "@/core/helpers/documentation";

export default defineComponent({
  name: "updates",
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Updates");
    });

    return {
      themeName,
    };
  },
});
